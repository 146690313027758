body {
  padding-top: 3.5rem;
}

/* sm/xs */
.jumbotron-image {
    width: auto;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/* md */
@media (min-width: 768px) {
    .jumbotron-image {
        width: auto;
        height: 250px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
/* lg */
@media (min-width: 992px) {
    .jumbotron-image {
        width: auto;
        height: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
/* xl */
@media (min-width: 1200px) {
    .jumbotron-image {
        width: auto;
        height: 350px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

/* sm/xs */
.employee-image {
    width: 75px;
    height: auto;
}
/* md */
@media (min-width: 768px) {
    .employee-image {
        width: 75px;
        height: auto;
    }
}
/* lg */
@media (min-width: 992px) {
    .employee-image {
        width: 100px;
        height: auto;
    }
}
/* xl */
@media (min-width: 1200px) {
    .employee-image {
        width: 100px;
        height: auto;
    }
}
